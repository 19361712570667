body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: monospace;
}

pre {
  margin: 0;
}


/* Highlight */
.hljs .json {
  background: "none";
  margin: "0px";
}

.hljs-attr {
  color: #0550ae
}

.hljs-string {
  color: #0a3069;
}

.hljs-literal {
  color: #0550ae;
}

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

.App {
    text-align: center;
}

/* Input Adornment */
.MuiInputAdornment-positionEnd {
    margin-right: 20px !important;
}

.MuiInputAdornment-positionStart {
    margin-left: 20px !important;
}


/* PDF Viewer */
.react-pdf__Page__annotations .annotationLayer {
    padding: 20px;
}

input#dateBirth::placeholder {
    color: transparent;
}


/* Mui phone number (flag size) */
.MuiPhoneNumber-flagButton > * {
    height: 20px;
}

/* Scrollbar */
/* TODO : See how to implements it in theme with CssBaseline */

@media only screen and (min-device-width: 600px) {
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #e2e5e4;
    }

    ::-webkit-scrollbar-thumb {
        background: #929596;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #757778;
    }
}
